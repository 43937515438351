
/*NavigationBar*/
/* 
.mainB{
    position: fixed; 
     width: 1265px; 
    
}  */

/* .mainB{

-webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-height: 50px;
    -webkit-transition: height 0.4s ease-in-out;
    transition: height 0.4s ease-in-out;
    overflow: visible;
    width: 100%;
    z-index: 49;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 50px;
    background-color: #02707A;
}


.navContainer{
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 200;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 1440px;
    padding: 0 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
}
*/
.navContainer{
    background-color: rgb(14, 103, 103);
    height: 45px;
    
    
}

.zip{
  
    color: white;
    padding-top: 15px;
    font-weight:200px;
    font-size: small;
    padding-left: 20px;
    float: left;
}
.zipA{
font-family: sans-serif;
text-decoration: none;
font-weight:300;
color: white;
}




/* .firstd{
    max-height: 64px;
    background-color: #E5BE4E;
} */


/* 
.fird{
    line-height: normal;
    box-shadow: rgb(0 0 0 / 40%) 0px 8px 12px -12px;
    max-height: 64px;
    color: #fff;
    font-size: 1.8rem;
    -webkit-transition: max-height 0.4s ease-in-out,height 0.4s ease-in-out;
    transition: max-height 0.4s ease-in-out,height 0.4s ease-in-out;
    top: 0;
    font-weight: 300;
    width: 100%;
    z-index: 1049;
    padding: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
} */

.drop{
    float: right;
    margin-right: 100px;
    max-height: 40px;
  
}
.drophead{
    color: white;
    float: left;
    
}

.navsub{
    color: white;
    /* text-decoration: none; */
    font-family: sans-serif;
}




/*Header*/

.fird{
    display: flex;
}

.divrow{
        
    
    background-color: gold;
    /* position: relative; */
    min-width: 100%;
    width: 1265px; 
}

.col1{
    background-color:chocolate;
    max-width: 55px;
    padding: 0.5%;
    margin: 0%;
    float:left;
}
.col2{
    background-color: rgb(13, 121, 188);
    float: left;
   
}


.logocss{
    max-height: 53px;
    
    
}

.home{
    float: right;
    margin-top: 18px;
    margin-right: 70px;
}

.ahome{
    
    color: rgb(47, 44, 44);
    font-weight: bolder;
}

/* sub Body */

.mbody{
    margin: 0 auto;
    max-width: 1920px;
    padding: 0;


    /* display: flex;
    flex: 0 1 100%;
    margin: 0px auto;
    flex-direction: column;
    min-width: 769px;
    max-width: 1201px;
    font-family: Proxima-Nova, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgb(65, 64, 66); */
}
.subbody{
max-height: 180px;
background-color:rgb(233, 247, 252);
}

.rcol1{
    
    height: 140px;
    padding: 45px;
   max-height: 160px;

}
.rcol2{
    max-height: 140px;
   
}
.rcol3{
    max-height: 140px;
}

.subrow
{
    max-height: 180px;
    font-family: freight-text-pro;
}

.rcolhead{
    float: right;
}

.rcolh{
    color: rgb(72, 72, 73);
    font-size: 28px;
    font-weight: 900;
    font-family: freight-text-pro;
    line-height: 1.25;
    flex-wrap: nowrap;
}
.rcola{
    float: right;
    margin-right: 40px;
    margin-top: 0%;
    padding-top: 0%;
}
.rcol2{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-top: 45px;
}
.rrcol1{
    max-width: 180px;
    float: left;
}
.rrcol2{
    max-width: 180px;
    float: right;
    margin-right: 40px;
}
.rinp{
width: 180px;
height: 35px;
}

.rform{
    margin-top: 65px;
    width: 180px;
}



/* Footer */
.footer{
    background-color:rgb(232, 232, 232);
    min-height: 350px;
}

.uldiv{
   
    flex: 0 1 auto;
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    max-width: 470px;
    margin: 0px auto;
    padding: 0px;
    max-height: 80px;
    font-size: 18px;
   
    
}
.ulrow{
    list-style: none;  
    margin-top: 50px; 
}


.li{
    display: inline-block;
    margin: 20px;
}

.atag{
    
    color: rgb(7, 88, 7);
}


.uldiv2{
   
    flex: 0 1 auto;
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    /* max-width: 470px; */
    font-size: 13px;
    margin-bottom: 15px;
    
    padding: 0px;
}
.ulrow2{
    list-style: none;   
    margin-bottom: 0px;
}

.fli{
    display: inline-block;
    margin: 20px;
}

.classa{
    text-decoration: none;
    color: rgb(23, 36, 23);
}

.uldiv3{
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
    margin-top: 0px;
}   



