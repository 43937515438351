/* main body */


.body{
    max-width: 1200px;
    margin-left: 35px;
    
}

.bodymain{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bolder;
    font-size: 40px;
    color: rgb(6, 116, 116);
    text-align: center;
    
}
/* .colb{
    border: 1px solid black;
} */
.container{
    display: grid;
    column-gap: 0%;
    row-gap: 0%;
    margin-bottom: 50px;
    
}


.rolb{
    min-height: 270px;
    display: grid;
    column-gap: 0%;
    row-gap: 0%;
    
    
}
.colb1{
    
    min-height: 135px;
}
.imgP{
    width: 200px;
    min-height: 300px;
    /* max-width: 100%;
        max-height: 100%; */
        display: block;
        padding: 0%;
        margin: 0%;
        
}
.imgP{
    max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0%;
}

.textContainer{
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    max-width: 55%;
    margin-left: 20%;
}
.textCont{
    margin-bottom: 110px;
}
.textsec{
font-family: Georgia, 'Times New Roman', Times, serif;
font-weight: bolder;
font-size: 40px;
color: rgb(43, 48, 48);
text-align: center;
margin-bottom: 60px;
}

/* */
.divhome{
    text-decoration: none;
    color: teal;
}

.divtable{
    margin-left:420px;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 30px;
    min-width: 200px;
}

.divcard{
    font-size: large;
    font-weight: 10px;
}